import { get,post } from '../common/request.js';

const api = "/api/"
const digApi = "/digApi/"
const digCApi = "/digCApi/"
const sgApi = "/sgApi/"

class ActivityApi{
    //急聘
    getActivityList(params){
        let url = sgApi + "position/api/activity/getActivityList";
        return get(url,params);
    }


}

export  {
    ActivityApi
};