import React, { useState, useEffect,useRef } from 'react';
import PageLoading from '../speakLoading/pageLoading'

import { useDispatch } from 'react-redux';
import {setTimerData, clearTimerData, setTimerType, clearTimerType} from '../../store/actions'


import 'swiper/css/navigation';
import 'swiper/css/autoplay'
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper/modules';
const ActivityList = ({infoData}) => {
    const dispatch = useDispatch();


    const [loading, setLoading] = useState(false);
    const [imgs, setImages] = useState([]);


    useEffect(() => {

        if(infoData && infoData.activityImgs){

            let arr = infoData.activityImgs.split(",");
            setImages(arr);

        }

        return () => {
        }

    }, []);




    return (
        <div style={{padding:'30px', overflow: 'auto', height: 'calc(100% - 40px)'}}>
            {
                imgs.map((item, index) => (
                    <p style={{ width: '100%', textAlign: 'center'}}><img src={item} alt="" width={'70%'} /></p>
                ))
            }

            <p style={{height: '200px'}}></p>

        </div>
    );
};

export default ActivityList;