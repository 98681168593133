import React, { useState, useEffect,useRef } from 'react';
import styles from './activityList.module.less'
import filters from '../../utils/filters.js';
import hotIcon from '../../assets/img/urgent.png';
import {ActivityApi} from "../../services/activityApi.js";
import PageLoading from '../speakLoading/pageLoading'
import resumeDefault from '../../assets/img/resume_default.png'

import { useDispatch } from 'react-redux';
import {setTimerData, clearTimerData, setTimerType, clearTimerType} from '../../store/actions'
import { Carousel } from 'antd';

import last from '../../assets/img/home/last.png'
import next from '../../assets/img/home/next.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'
import 'swiper/css';
import { Navigation, Autoplay } from 'swiper/modules';
const ActivityList = ({indCode,  onData}) => {
    let activityApi = new ActivityApi();
    const dispatch = useDispatch();

    const [activityData, setActivityData] = useState(Array)

    const [loading, setLoading] = useState(false);
    let pageOption = useRef({
        pageSize: 10,
        pageNo: 0,
        total: 0,
        pageCount: 0,
        positionTypeInt:0,
        other:{}
    })
    const [previousPage, setPreviousPage] = useState(false);
    const [nextPage, setNextPage] = useState(false);

    const [firstLoading, setFirstLoading] = useState(false);



    const lastExecutedTimeRef = useRef(0)


    useEffect(() => {
        dispatch(setTimerType("positionList"))

        pageOption.indCode =
        pageOption.current.pageNo=0
        pageOption.current.total=0
        pageOption.current.pageCount=0
        pageOption.current.other= {}

        let newObj=JSON.stringify(pageOption.current)


        if (pageOption.current.pageNo===0){
            getList(pageOption.current)
        }

        return () => {
            console.log("activityList 卸载--------------------------------")
            dispatch(clearTimerType())
        }

    }, []);

    // 获取职位列表
    const getList = (e) =>{
        setLoading(true);
        let obj=e?e:pageOption.current
        console.log('@@@@@@@@@',obj)

        let params = {
            indCode: indCode,
            pageNo: e?e.pageNo:(pageOption.current.pageNo ? pageOption.current.pageNo : 0),
            pageSize: pageOption.current.pageSize
        }
        activityApi.getActivityList(params).then((res) => {
            if(res.data.data.list) {
                pageOption.current.total=res.data.data.count
                pageOption.current.pageCount=res.data.data.page_count

                setActivityData( prevData => prevData.concat(res.data.data.list));
                setLoading(false);
                setFirstLoading(false)
            }

        });
    }


    // 跳转职位详情 向父组件传参
    function routTo(data) {
        // localStorage.setItem('positionTypeInt',chooseBtn);
        const dataToSend = data;
        onData(dataToSend);
    }

    function refreshTimer() { }

    // 上一页
    function lastPage() {
        if (!previousPage) {
            setPreviousPage(true);
            setTimeout(() => {
                if(pageOption.pageNo) {
                    pageOption.current.pageNo=pageOption.current.pageNo - 1
                }
                setPreviousPage(false);
            }, 800);
        }
    }
    // 下一页
    function nexPage() {
        if (!nextPage) {
            setNextPage(true);
            setTimeout(() => {
                pageOption.current.pageNo= pageOption.current.pageNo == null ? 1 : pageOption.current.pageNo + 1
                let obj= localStorage.getItem('listSearch')
                if (obj){
                    obj=JSON.parse(obj)
                    obj.pageNo++
                    let newObj=JSON.stringify(obj)
                    localStorage.setItem('listSearch',newObj)
                }
                setNextPage(false);
            }, 800);
        }
    }

    //分页
    useEffect(() => {
        let obj= localStorage.getItem('listSearch')
        obj=JSON.parse(obj)
        console.log('dddddddddddddddd',obj)
        if(pageOption.current.pageNo !== 0){
            getList(pageOption.current);
        }
    }, [pageOption.current.pageNo]);

    const handleScroll = (event) => {
        // console.log(event, '滚动事件');
        let el = event.target;
        if( el.scrollTop + el.clientHeight + 2 >= el.scrollHeight ) {
            if(pageOption.current.pageNo<pageOption.current.pageCount) {
                const now = Date.now()
                if (now - lastExecutedTimeRef.current > 1000) {

                    lastExecutedTimeRef.current = now;
                    // if ((pageOption.current.pageNo == null || pageOption.current.pageNo < pageOption.current.pageCount) && pageOption.current.pageCount !== 1) {
                    if(el.scrollTop>0) {
                        setLoading(true);
                        nexPage();
                    }
                    // }
                }
            }
        }
    };

    const card = useRef();
    const swiperRef = useRef(null);
    function getSwiper(){
        const swiperInstance = swiperRef.current;
        // 修改导航按钮颜色
        if (swiperInstance) {
            swiperInstance.children[1].style.color = '#A6D1FB';
            swiperInstance.children[2].style.color = '#A6D1FB';
        }
    }

    return (
        <div className={styles.activityList} onClick={refreshTimer}  onScroll={handleScroll}>
            <p></p>

            {
                activityData.length > 0? (
                    <div className={styles.listScroll} onClick={refreshTimer}>

                        <div className={styles.list} >
                            {activityData.map((item, index) => (
                                <article onClick={() => routTo(item)} key={index}>
                                    <div className={styles.listCon}>

                                        <section>
                                            <div className={styles.listCon_left} >
                                                <img src={item.activityLogo} alt="" style={{width: '125px'}} />
                                            </div>
                                            <div className={styles.listCon_right} >
                                                <div className={styles.listCon_right_top} style={{ padding: '10px 0'}}>
                                                    <b style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.activityTitle}</b>
                                                </div>
                                                <div className={styles.listCon_right_bottom} style={{paddingBottom: '10px',height: '80px'}}>
                                                    <p style={{wordWrap: 'break-word', overflowWrap: 'break-word',height: '130px',lineHeight: '25px'}}>{item.activityContent.length > 50 ? `${item.activityContent.substring(0, 50)}...` : item.activityContent}</p>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                </article>
                            ))}
                        </div>
                        {
                            loading ? (
                                <div className={styles.loadingWrap}>
                                    <div className={styles.load_container}>
                                        <PageLoading></PageLoading>
                                    </div>
                                </div>
                            ) : ('')
                        }
                        <div className={styles.blockHeight}></div>
                    </div>
                ) : (
                    loading ? (
                        <div className={styles.loadingWrap}>
                            <div className={styles.load_container}>
                                <PageLoading></PageLoading>
                            </div>
                        </div>
                    ):(
                        <div className={styles.list}>
                            <img src={resumeDefault} alt="" />
                            <span>暂无数据</span>
                        </div>
                    )
                )
            }
        </div>
    );
};

export default ActivityList;